<template>
  <div>权限</div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'


export default defineComponent({
  props: {},
  components: {},
  setup() {
    return {}
  }
})
</script>

<style scoped lang="less">
</style>





